<template>
	<div
		class="wrap__content"
		v-bind:class="{ 'wrap__content--preloader': permissionsIntegrateUpdate }"
	>
		<v-preloader v-if="permissionsIntegrateUpdate" :message="errorMessage"></v-preloader>

		<div v-if="!permissionsIntegrateUpdate" class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['PermissionManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.permission') }}
										</div>
									</div>
								</div>
							</div>

							<div v-if="permissionsIntegrateList.length" class="card__content--body">
								<div class="table__block--content user-profile__block--content">
									<div class="user-profile__block--update">
										<button
											:disabled="permissionsIntegrateUpdate"
											class="user-profile--update"
											@click="permissionsIntegration"
										>
											<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.625 1.583v4.75h-4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.375 9.5A7.125 7.125 0 0114.25 4.196l2.375 2.137M2.375 17.417v-4.75h4.75" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.625 9.5A7.126 7.126 0 014.75 14.804l-2.375-2.137" stroke="#6D90B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
											обновить
										</button>
									</div>

									<v-data-table
										v-if="permissionsIntegrateList"
										:headers="[
											{ text: 'Название', value: 'ActivityType' },
											{ text: 'Номер', value: 'GlobalUniqueNumber' },
											{ text: 'Никад', value: 'Nikad' },
											{ text: this.$t('headers.status'), value: 'Status' },
											{ text: 'Дата выпуска', value: 'IssueDate', width: '100px' },
											{
												text: 'Результаты PDF',
												value: 'DocumentUrl',
												align: 'center',
												sortable: false,
												width: '150px',
											},
										]"
										:items="permissionsIntegrateList"
										:loading="false"
										:options.sync="options2"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
									>
										<template v-slot:item.ActivityType="{ item }">
											<span v-if="$i18n.locale == 'ru'">
												{{ item.ActivityType['NameRu'] }}
											</span>
											<span v-if="$i18n.locale == 'kz'">
												{{ item.ActivityType['NameKz'] }}
											</span>
											<span v-if="$i18n.locale == 'en'">
												{{ item.ActivityType['NameEn'] }}
											</span>
										</template>
										<template v-slot:item.GlobalUniqueNumber="{ item }">
											{{ item.GlobalUniqueNumber }}
										</template>
										<template v-slot:item.IssueDate="{ item }">
											{{ item.IssueDate | formatDate }}
										</template>
										<template v-slot:item.Status="{ item }">
											<span v-if="$i18n.locale == 'ru'">
												{{ item.Status['NameRu'] }}
											</span>
											<span v-if="$i18n.locale == 'kz'">
												{{ item.Status['NameKz'] }}
											</span>
											<span v-if="$i18n.locale == 'en'">
												{{ item.Status['NameRu'] }}
											</span>
										</template>
										<template v-slot:item.DocumentUrl="{ item }">
											<a
												:href="item.DocumentUrl"
												class="tb__table--btn tb__table--delete"
												target="_blank"
												v-tooltip.top-center="{
													content: $t('button.download'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.304 7.295v20.947c0 .967.79 1.758 1.758 1.758h17.984c.966 0 1.757-.791 1.757-1.758V1.758C25.803.79 25.013 0 24.046 0H11.599" fill="#E74C3C"/><path d="M4.304 7.295H9.84c.967 0 1.758-.791 1.758-1.758V0" fill="#C0392B"/><path d="M2.397 19.653h1.907V17.14l-2.079 2.589c.036-.046.095-.075.172-.075zM27.71 19.653h-1.907V17.14l2.079 2.589c-.036-.046-.095-.075-.172-.075z" fill="#262626"/><path d="M25.907 25.238a.456.456 0 01-.396.275H4.596a.456.456 0 01-.396-.275l-1.994-5.31c-.056-.151.03-.275.19-.275H27.71c.161 0 .246.124.19.275l-1.993 5.31z" fill="#C0392B"/><path d="M9.822 23.974c0-.147.043-.26.13-.34.087-.08.214-.12.38-.12.158 0 .28.04.364.12.084.08.127.194.127.34a.436.436 0 01-.13.337.51.51 0 01-.361.12.547.547 0 01-.374-.12.425.425 0 01-.136-.337zM13.952 21.984c0 .392-.115.694-.346.907-.23.213-.558.32-.983.32h-.265v1.16h-.946v-3.5h1.211c.443 0 .775.097.997.29.221.193.332.467.332.823zm-1.594.455h.172a.47.47 0 00.339-.12.435.435 0 00.126-.33c0-.236-.131-.354-.393-.354h-.244v.804zM17.482 22.547c0 .584-.16 1.035-.483 1.35-.321.317-.773.475-1.356.475H14.51V20.87h1.212c.562 0 .996.144 1.301.431.306.287.459.702.459 1.245zm-.982.034c0-.321-.063-.559-.19-.714-.127-.155-.32-.232-.578-.232h-.276v1.961h.21c.288 0 .499-.083.633-.25.134-.167.201-.422.201-.765zM19.04 24.372h-.93V20.87h2.068v.759h-1.137v.668h1.049v.76h-1.05v1.314zM21.386 10.835c0 .241-.263.44-.586.44H9.224c-.322 0-.586-.199-.586-.44 0-.242.264-.44.586-.44H20.8c.323 0 .586.198.586.44zM21.362 13.471c0 .242-.264.44-.586.44H9.2c-.323 0-.586-.198-.586-.44 0-.241.263-.44.586-.44h11.576c.322 0 .586.199.586.44zM21.386 16.114c0 .241-.263.44-.586.44H9.224c-.322 0-.586-.198-.586-.44s.264-.44.586-.44H20.8c.323 0 .586.198.586.44z" fill="#fff"/></svg>
											</a>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											Ваш поиск по запросу не дал результатов.
										</v-alert>
									</v-data-table>

									<div
										class="form__block--line form__messages"
										:class="{
											'form__messages--error': permissionsMessage.status != 200,
											'form__messages--success': permissionsMessage.status == 200,
										}"
										v-if="permissionsMessage.status"
									>
										<label class="form__block--input">
											<span class="form__block--title">
												{{ permissionsMessage.text }}
											</span>
										</label>
									</div>
								</div>
							</div>
							<div class="card__content--body" v-else>
								<div class="form__line--title">
									<center>Нет разрешений</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	data() {
		return {
			permissionsIntegrateList: [],
			permissionsIntegrateUpdate: true,
			permissionsMessage: {
				status: null,
				text: null,
			},
			options2: {
				itemsPerPage: 5,
				page: 1,
			},

			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
				texts: null,
			},
		}
	},
	methods: {
		permissionsIntegration() {
			this.permissionsIntegrateUpdate = true

			this.permissionsMessage = {
				status: null,
				text: null,
			}

			api
				.get('integration/user/' + this.$route.params.userId + '/permissions-doc')
				.then((response) => {
					if (response.status == 200) {
						const permissionsIntegrateData = response.data.data

						if (
							permissionsIntegrateData.response &&
							permissionsIntegrateData.response.responseData &&
							permissionsIntegrateData.response.responseData.data &&
							permissionsIntegrateData.response.responseData.data.Licenses
						) {
							this.permissionsIntegrateList =
								permissionsIntegrateData.response.responseData.data.Licenses.UniversalLicense.slice().reverse()
						}
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error?.response?.status == 500) {
							this.permissionsMessage.status = 500
							this.permissionsMessage.text = this.$t('system_message.500')
						} else if (error?.response?.status == 401) {
							this.permissionsMessage.status = 401
							this.permissionsMessage.text = error.response.data.error_message
							this.$router.push('/' + this.$i18n.locale + '/login')
						} else if (error?.response?.status == 404) {
							this.permissionsMessage.status = error?.response?.status
							this.permissionsMessage.text = this.$t('system_message.500')
						} else {
							this.permissionsMessage.status = error?.response?.status
							this.permissionsMessage.text = error.response.data.error_message
						}
						if (error?.response?.status == 403) {
							this.errorMessage.status = 403
							this.errorMessage.text = error?.response?.data?.message
						}
					}
				})
				.finally(() => {
					this.permissionsIntegrateUpdate = false
				})
		},
	},
	beforeMount() {
		this.permissionsIntegration()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.users_parent'),
			}
		},
		meta: [],
	},
}
</script>
